var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"persistent":"","content-class":"error-dialogue"},model:{value:(_vm.showBatchModal),callback:function ($$v) {_vm.showBatchModal=$$v},expression:"showBatchModal"}},[_c('div',{staticClass:"d-flex flex-column",staticStyle:{"height":"90vh"}},[_c('div',{staticClass:"pb-2 pt-2 d-flex",staticStyle:{"height":"15vh"}},[_c('div',{staticClass:"legend"},[(!_vm.showResultsModal)?_c('div',[_c('div',{staticClass:"color",staticStyle:{"background-color":"#fff9bf"}}),_c('span',[_vm._v(" - New Metal Required")])]):_vm._e(),(!_vm.showResultsModal)?_c('div',[_c('div',{staticClass:"color",staticStyle:{"background-color":"#8ee5ee"}}),_c('span',[_vm._v(" - New Class Required")])]):_vm._e(),(_vm.showResultsModal)?_c('div',[_c('div',{staticClass:"color",staticStyle:{"background-color":"#ffd9d9"}}),_c('span',[_vm._v(" - Error")])]):_vm._e(),(_vm.showResultsModal)?_c('div',[_c('div',{staticClass:"color",staticStyle:{"background-color":"#b1dfbb"}}),_c('span',[_vm._v(" - Completed")])]):_vm._e(),(_vm.showResultsModal)?_c('div',[_c('div',{staticClass:"color",staticStyle:{"background-color":"#b8daff"}}),_c('span',[_vm._v(" - New Metal Assigned")])]):_vm._e()]),_c('div',{staticClass:"pb-2 pt-2 d-flex justify-space-around",staticStyle:{"width":"100%"}},[(_vm.showResultsModal)?_c('div',{staticClass:"text-h3"},[_vm._v("Batch Results")]):(
              !_vm.newMetalPlateClasses || _vm.newMetalPlateClasses.length === 0
            )?_c('div',{staticClass:"text-h3"},[_vm._v(" No New Metal Classes ")]):_vm._l((_vm.newMetalInventoryCounts),function(plateClass,index){return _c('div',{key:index,attrs:{"align":"center"}},[_c('p',[_vm._v(" New Metal Class: "+_vm._s(plateClass.class)+" "),_c('br'),_vm._v(" Plate Quantity in Range: "+_vm._s(plateClass.availableMaxCount)),_c('br'),(plateClass.availableMaxCount > 0)?_c('span',[_vm._v("First Plate in Range: "+_vm._s(plateClass.firstPlateInRange))]):_vm._e(),_c('br'),_vm._v(" Plates To Be Used: "+_vm._s(plateClass.inUseCount)+" ")])])})],2)]),_c('div',{staticClass:"bootstrap pb-2",staticStyle:{"height":"65vh"}},[_c('b-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.showResultsModal),expression:"showResultsModal"}],ref:"batchResultsTable",attrs:{"sticky-header":"65vh","sort-icon-left":"","outlined":"","items":_vm.batchedResults,"fields":_vm.batchedResultFields,"head-variant":"light"}}),_c('b-table',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showResultsModal),expression:"!showResultsModal"}],ref:"batchPreviewTable",attrs:{"selectable":"","sticky-header":"65vh","sort-icon-left":"","select-mode":"multi","outlined":"","items":_vm.batchedTransactions,"fields":_vm.batchedRenewalFields,"head-variant":"light"},on:{"sort-changed":_vm.handleDeselectAll,"row-clicked":_vm.handleRowClick}})],1),_c('div',{staticClass:"d-flex justify-space-around align-center",staticStyle:{"height":"100%"},attrs:{"align":"center"}},[(_vm.showResultsModal)?[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.showResultsModal = false;
              _vm.batchedResults = [];
              _vm.$emit('cancel');}}},[_vm._v("Okay")])]:[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.selectPreviewTableRows(10)}}},[_vm._v("Select 10")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.selectPreviewTableRows(50)}}},[_vm._v("Select 50")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.selectPreviewTableRows(_vm.batchedTransactions.length)}}},[_vm._v("Select All")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.handleDeselectAll}},[_vm._v("Deselect All")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function () {
                _vm.showResultsModal = true;
                _vm.doBatch();
              }}},[_vm._v("Batch")]),_c('v-btn',{attrs:{"color":"red"},on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v("Cancel")])]],2)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }