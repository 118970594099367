<template>
  <div class="outer panel">
    <div class="above-grid">
      <div class="flex-space-between">
        <div class="flex-space-between" @keydown.enter="handleSearch()">
          <template v-if="view === 'renewals'">
            <star-date-picker
              v-model="renewalBeginDate"
              label="Begin Date"
              :displayFormat="BDPProps.displayFormat"
              :textFieldProps="{ ...BDPProps.TextField, clearable: true }"
              :datePickerProps="BDPProps.DatePicker"
              :menuProps="BDPProps.Menu"
              modelFormat="MM/DD/YYYY"
            />

            <star-date-picker
              v-model="renewalEndDate"
              label="End Date"
              :displayFormat="BDPProps.displayFormat"
              :textFieldProps="{ ...BDPProps.TextField, clearable: true }"
              :datePickerProps="BDPProps.DatePicker"
              :menuProps="BDPProps.Menu"
              modelFormat="MM/DD/YYYY"
            />

            <v-text-field v-model="plateNo" label="Plate Number" />
            <v-select
              v-model="statusCodes"
              :items="searchableStatuses"
              @change="handleSearch()"
            ></v-select>
          </template>
          <template v-else>
            <star-date-picker
              v-model="batchDate"
              label="Batch Date"
              :textFieldProps="BDPProps.TextField"
              :datePickerProps="BDPProps.DatePicker"
              :menuProps="BDPProps.Menu"
              modelFormat="YYYY-MM-DD"
              displayFormat="MM/DD/YYYY"
            />
          </template>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" @click="handleSearch()" color="blue" icon>
                <v-icon>search</v-icon>
              </v-btn>
            </template>
            <span>Search</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                @click="
                  clear();
                  handleSearch();
                "
                color="red"
                icon
              >
                <v-icon>close</v-icon>
              </v-btn>
            </template>
            <span>Clear</span>
          </v-tooltip>
        </div>
        <div class="d-flex align-center">
          <div
            class="legend"
            v-if="batchViewLevels.length === 2 || !isBatchesView"
          >
            <div v-if="view !== 'renewals'">
              <div class="color" style="background-color: #b8daff"></div>
              <span> - New Metal Assigned</span>
            </div>
            <div v-if="['3P', 'R'].includes(statusCodes)">
              <div class="color" style="background-color: #ffd9d9"></div>
              <span v-if="this.statusCodes === 'R'"> - Rejected</span>
              <span v-else> - Error</span>
            </div>
            <div v-if="view === 'renewals' && [7, '3P'].includes(statusCodes)">
              <div class="color" style="background-color: #fff9bf"></div>
              <span v-if="statusCodes === '3P'"> - New Metal Required</span>
              <span v-else> - Void</span>
            </div>
            <div v-if="[7, '3P'].includes(statusCodes)">
              <div class="color" style="background-color: #8ee5ee"></div>
              <span> - New Class Required</span>
            </div>
            <div v-if="view !== 'renewals'">
              <div class="color" style="background-color: #b1dfbb"></div>
              <span> - Completed</span>
            </div>
          </div>
          <v-btn
            v-if="view === 'batches' && batchViewLevel === 1"
            color="primary"
            @click="reprint()"
            >Reprint All</v-btn
          >
          <v-btn
            v-if="view === 'renewals'"
            @click="initBatchDialogue(500)"
            color="primary"
            >Batch</v-btn
          >
        </div>
      </div>
    </div>
    <div class="table-controls">
      <div v-if="view === 'renewals'">
        <h2 class="table-navigation renewal-table-nav">
          Renewals ({{ renewalCount }})
        </h2>
      </div>
      <div v-else class="flex-center">
        <v-breadcrumbs :items="batchViewLevels" divider=">">
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item @click="item.click"
              ><h2 class="table-navigation">
                {{ item.text }}
              </h2></v-breadcrumbs-item
            >
          </template>
        </v-breadcrumbs>
      </div>
      <div class="flex-center renewal-view-control">
        <div class="view-lable" @click="view = 'renewals'">Renewals</div>
        <v-switch v-model="isBatchesView"></v-switch>
        <div class="view-lable" @click="view = 'batches'">Batches</div>
      </div>
    </div>
    <div class="bootstrap">
      <b-table
        sticky-header
        id="renewal-grid"
        v-show="view === 'renewals' || batchViewLevel === 1"
        sort-icon-left
        hover
        head-variant="light"
        responsive="sm"
        :no-border-collapse="true"
        :fields="renewalFields"
        :items="renewals"
        :busy="isBusy"
        :per-page="10000"
        @row-clicked="item => loadRenewalTransaction(item)"
        :show-empty="true"
      >
        <template #table-busy>
          <strong class="center align-middle">Loading...</strong>
        </template>

        <template #cell(selected)="{ item, rowSelected }">
          <v-checkbox
            v-if="item.status === 'COMPLETE'"
            :value="rowSelected"
            readonly
          ></v-checkbox>
          <div
            v-else-if="item.errorMessage !== undefined"
            @click="showError(props.row.errorMessage, $event)"
          >
            <v-icon>
              error
              <v-tooltip>
                {{ item.errorMessage }}
              </v-tooltip>
            </v-icon>
          </div>
        </template>

        <template #cell(paymentType)="data">
          <template v-for="tenderType in data.item.tenderType">
            <v-tooltip :key="tenderType" bottom>
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  <v-icon color="black">
                    {{ tenderIcons[tenderType] }}
                  </v-icon>
                </span>
              </template>
              {{ tenderTooltips[tenderType] }}
            </v-tooltip>
          </template>
        </template>
        <template #cell(locked)="data">
          <v-tooltip
            v-if="![null, undefined, ''].includes(data.item.assignedTo)"
            bottom
            :disabled="[undefined, null].includes(data.item.assignedUser)"
          >
            <template v-slot:activator="{ on }">
              <span v-on="on" icon>
                <v-icon color="black">lock</v-icon>
              </span>
            </template>
            <span v-if="![undefined, null].includes(data.item.assignedUser)">
              {{
                data.item.status == "REJECTED" ? "Rejected by" : "Assigned to"
              }}
              {{ data.item.assignedUser.fullname }}
            </span>
          </v-tooltip>
        </template>
        <template #cell(accept)="data">
          <template v-if="data.item.statusCode === '3'">
            <v-tooltip
              bottom
              :disabled="canBeAccepted(data.item) && hubAppIsResponsive"
            >
              <template v-slot:activator="{ on, attrs }">
                <span v-on="on" v-bind="attrs">
                  <v-btn
                    v-if="data.item.transactionTypeCode !== 'PT'"
                    :class="{ invisible: data.item.status !== 'PENDING' }"
                    :disabled="!canBeAccepted(data.item) || !hubAppIsResponsive"
                    color="green"
                    @click="e => assignAndProcess(e, data.item, 'accept')"
                  >
                    Accept
                  </v-btn>
                </span>
              </template>
              <span>
                {{ acceptTooltipText(data.item) }}
              </span>
            </v-tooltip>
          </template>
        </template>
        <template #cell(reject)="data">
          <template v-if="data.item.statusCode === '3'">
            <v-btn
              v-if="data.item.transactionTypeCode !== 'PT'"
              :class="{ invisible: data.item.status !== 'PENDING' }"
              @click="e => assignAndProcess(e, data.item, 'reject')"
              color="red"
            >
              Reject
            </v-btn>
          </template>
        </template>
        <template #cell(plateClassCode)="data">
          {{
            data.item.transactionTypeCode === "PT"
              ? "Placard"
              : data.item.plateClassCode
          }}
        </template>
        <template #cell(reprint)="data">
          <template v-if="data.item.status === 'COMPLETE'">
            <v-btn @click="e => reprintRenewal(e, data.item)" color="primary">
              Reprint</v-btn
            >
          </template>
        </template>
      </b-table>
      <b-table
        sticky-header
        id="batch-grid"
        v-show="view === 'batches' && batchViewLevel === 0"
        :fields="batchFields"
        :items="batches"
        hover
        sort-icon-left
        responsive="sm"
        head-variant="light"
        :no-border-collapse="true"
        :busy="isBusy"
        :show-empty="true"
        :per-page="10000"
        @row-clicked="item => handleBatchRowClick(item)"
      >
        <template #table-busy>
          <strong class="center align-middle">Loading...</strong>
        </template>
        <template #cell(reprint)="data">
          <v-btn
            @click="e => reprintBatch(e, data.item.batchID)"
            color="primary"
          >
            Reprint</v-btn
          >
        </template>
      </b-table>
      <batchORGrid
        :showBatchModal="showBatchModal"
        :batchedTransactions="batchedTransactions"
        @cancel="
          () => {
            showBatchModal = false;
            batchedTransactions = [];
            this.handleSearch();
          }
        "
      />
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";

// mixins
import vehicleFunctions from "@/mixins/vehicleFunctions";
import renewal from "@/mixins/renewal.mixin";
import transaction from "@/mixins/transaction.mixin";
import date from "@/mixins/date.mixin";

import starDatePicker from "@/components/nonPageComponents/starDatePicker";
import batchORGrid from "@/components/nonPageComponents/BatchORGrid";
import { mapGetters } from "vuex";
import { formatErrorResponse } from "@/assets/js/format.js";

export default {
  name: "OnlineRenewals",
  components: { starDatePicker, batchORGrid },
  mixins: [vehicleFunctions, renewal, transaction, date],
  data() {
    return {
      showBatchModal: false,
      batchedTransactions: [],
      view: "renewals", // information to display (true for renewals and false for batches which includes renewals that were batched when batchViewLevel is 1)
      isBatchesView: false,
      batchViewLevel: 0, // 0: batches that occured on selected date, 1: renewals within selected batch
      renewalBeginDate: null,
      renewalEndDate: null,
      hubAppIsResponsive: null,
      statusCodes: "3P", // pending
      batchDate: dayjs().format("YYYY-MM-DD"),
      batches: [],
      renewals: [],
      batchRenewals: [],
      plateNo: "",
      isBusy: false,
      pagesize: 50, // todo> increase after pagination testing
      page: 0,
      endOfRenewalsResults: false, // true if all results for the current renewal search fields have been obtained from the api, false
      renewalStatuses: {
        // a subset of transaction statuses that pertain to online renewals
        1: "COMPLETE",
        3: "PENDING",
        7: "VOID",
        R: "REJECTED", // renamed from County Rejected for display purposes
        P: "PENDING"
      },
      searchableStatuses: [
        {
          value: "3P",
          text: "PENDING"
        },
        {
          value: 7,
          text: "VOID"
        },
        {
          value: "R",
          text: "REJECTED"
        }
      ],
      renewalFields: [
        {
          key: "paymentType",
          label: "Payment",
          sortable: true
        },
        {
          formatter(value) {
            return dayjs(value).format("MM/DD/YYYY");
          },
          key: "transactionDate",
          label: "Date Submitted",
          sortable: true
        },
        {
          key: "plateNo",
          sortable: true
        },
        {
          key: "plateClassCode",
          label: "Class",
          sortable: true
        },

        {
          key: "currentExpiresDate",
          label: "Current Exp. Date",
          sortable: true,
          formatter(value) {
            if (value) {
              return (
                value.slice(5, 7) +
                "/" +
                value.slice(8, 10) +
                "/" +
                value.slice(0, 4)
              );
            } else {
              return null;
            }
          }
        },
        {
          key: "currentAddress",
          sortable: true,
          formatter(value) {
            return (
              value.address1 +
              " " +
              value.address2 +
              " " +
              value.state +
              " " +
              value.postalCode
            );
          }
        },
        {
          key: "customerName",
          label: "Owner",
          sortable: true
        },
        {
          key: "locked",
          label: "",
          sortable: false
        },
        {
          key: "accept",
          label: "",
          sortable: false
        },
        {
          key: "reject",
          label: "",
          sortable: false
        },
        { key: "reprint", label: "", sortable: false }
      ],
      batchFields: [
        { key: "batchID", label: "Batch", sortable: true },
        {
          formatter(value) {
            return dayjs(value).format("MM/DD/YYYY");
          },
          key: "batchMoment",
          label: "TimeStamp"
        },
        { key: "reprint" }
      ],
      transObj: this.$store.getters.transObj,
      tenderIcons: {
        CreditCard: "mdi-credit-card",
        Echeck: "mdi-checkbook"
      },
      tenderTooltips: {
        CreditCard: "Credit Card",
        Echeck: "E-Check"
      }
    };
  },
  methods: {
    async initBatchDialogue(batchSize) {
      this.$root.$emit("setLoading", true);

      this.batchedTransactions = await this.renewalSearch({
        pagesize: batchSize,
        statusCodes: "3P",
        page: 0
      });
      this.batchedTransactions = this.batchedTransactions.filter(
        renewal => renewal.isBatchable
      );

      if (this.batchedTransactions.length === 0) {
        this.$store.dispatch("setGlobalAlertState", {
          title: "Nothing to Batch",
          minWidth: "350px",
          description: "No Batchable Transactions Found.",
          actions: [
            {
              text: "Okay",
              handler: () => this.$store.dispatch("hideGlobalAlert"),
              color: "primary"
            }
          ]
        });
      } else {
        this.batchedTransactions = this.batchedTransactions.map(renewal => {
          if (
            !this.canBeAccepted(renewal) &&
            (this.acceptTooltipText(renewal) === "New Metal Needed" ||
              this.acceptTooltipText(renewal).includes("New Class"))
          ) {
            renewal.isNewMetal = true;
          }

          return renewal;
        });

        this.$root.$emit("setLoading", false);
        this.showBatchModal = true;
      }
    },
    async handleBatchRowClick(item) {
      this.renewals = this.batchRenewals = await this.renewalSearch({
        batchid: item.batchID
      });

      this.batchViewLevel = 1;
    },
    async reprint(renewals = this.renewals) {
      const transactions = await this.$api.reprintRegistration(
        renewals
          .filter(
            renewal =>
              renewal.transactionStatusCode === "1" ||
              renewal.statusCode === "1"
          )
          .map(renewal => renewal.transactionID)
      );

      this.printDecals(transactions);
    },
    async reprintBatch(event, id) {
      event.stopPropagation();
      const renewals = await this.renewalSearch({ batchid: id });
      this.reprint(renewals.filter(renewal => renewal.statusCode === "1"));
    },
    async reprintRenewal(event, renewal) {
      event.stopPropagation();
      this.reprint([renewal]);
    },
    async assignAndProcess(event, renewal, action) {
      event.stopPropagation();
      const customErrors = {
        400: async error => {
          this.$root.$emit("setLoading", false);

          const errorText = formatErrorResponse(error);

          if (errorText.includes("EIVS")) {
            this.handleEVIS();
            throw errorText;
          } else {
            this.$store.dispatch("setGlobalAlertState", {
              title: "Error",
              description: errorText,
              icon: "error"
            });
            throw errorText;
          }
        },
        403: async error => {
          this.$store.dispatch("setGlobalAlertState", {
            title: "Error",
            description: formatErrorResponse(error),
            icon: "error"
          });
          throw formatErrorResponse(error);
        }
      };
      this.$root.$emit("setLoading", true);
      const responses = await this.$api
        .transactionSearch(renewal.transactionID, true)
        .catch(e => console.error(e));
      this.$root.$emit("setLoading", false);

      if (action === "accept") {
        try {
          const transactionRequest = JSON.parse(responses[0].transactionJSON);

          this.concatenateStreetNoAndAddress(transactionRequest);

          if (this._.has(transactionRequest, "title")) {
            this.$store.commit("titleData", transactionRequest.title);
            delete transactionRequest.title;
          }

          transactionRequest.transaction.isVerify = true;
          const transactionResponse = await this.createTransaction(
            transactionRequest,
            customErrors
          );

          transactionRequest.transaction.isVerify = false;
          const transRequestResult = await this.createTransaction(
            transactionRequest
          );
          this.transObj = transactionRequest;
          this.$store.commit("transObj", this.transObj);
          const itemsToPrint = this.getItemsToPrint(
            transactionRequest,
            "Online Renewal",
            transRequestResult
          );
          this.printTransaction(
            itemsToPrint,
            transactionRequest,
            transactionResponse
          );
        } catch (e) {
          console.error(e);
          this.$root.$emit("setLoading", false);
          return;
        }
      }

      if (action === "reject") {
        if (!(await this.rejectRenewal(renewal))) return;
      }

      const renewalIndex = this.renewals.findIndex(
        testRenewal => renewal.transactionID === testRenewal.transactionID
      );

      this.renewals.splice(renewalIndex, 1);
    },
    showError(message, event) {
      event.stopPropagation();
      this.$store.dispatch("setGlobalAlertState", {
        title: "Error",
        description: message,
        icon: "error"
      });
    },
    queryString(params) {
      let queryString = "";
      let delimeter = "?"; // changes to & after first parameter
      const keys = Object.keys(params);

      keys.forEach(key => {
        if (![undefined, null, ""].includes(params[key])) {
          queryString += delimeter + key + "=" + params[key]; // add key=value to query string if it is non-empty
          if (delimeter === "?") delimeter = "&"; // change delimeter
        }
      });

      return queryString;
    },
    augmentRenewal(renewal) {
      // add front end parameters to a renewal
      renewal.transactionMoment = dayjs(renewal.transactionDate);
      renewal.statusMoment = dayjs(renewal.statusDate);
      renewal.selected = true;
      renewal.status = this.renewalStatuses[renewal.statusCode] || "";
      if (renewal.rejectReason) renewal.errorMessage = renewal.rejectReason;
      if (![null, undefined].includes(renewal.assignedTo))
        renewal.assignedUser = this.$store.getters.users.find(
          user => renewal.assignedTo === user.id
        );

      if (renewal.statusCode === "1" || renewal.transactionStatusCode === "1") {
        renewal._rowVariant = "success";
      }
      if (
        renewal.statusCode === "7" ||
        (!this.canBeAccepted(renewal) &&
          this.acceptTooltipText(renewal) === "New Metal Needed")
      ) {
        renewal._rowVariant = "warning";
      }
      if (renewal.newMetalAssigned) {
        renewal._rowVariant = "primary";
      }
      if (
        renewal.statusCode === "R" ||
        renewal.err ||
        (renewal.batchID &&
          (renewal.statusCode === "3" || renewal.transactionStatusCode === "3"))
      ) {
        renewal._rowVariant = "danger";
      }
      if (
        !this.canBeAccepted(renewal) &&
        this.acceptTooltipText(renewal).includes("New Class")
      ) {
        renewal._rowVariant = "info";
      }

      return renewal;
    },
    async renewalSearch(params = null) {
      const queryStringParams =
        params ||
        Object.fromEntries(
          Object.entries(this).filter(([key]) =>
            [
              "beginDate",
              "endDate",
              "plateNo",
              "statusCodes",
              "pagesize",
              "page"
            ].includes(key)
          )
        );
      const queryString = this.queryString(queryStringParams);
      const renewals = (await this.$api.renewalSearch(queryString)) || [];
      if (renewals.length < this.pagesize) this.endOfRenewalsResults = true;

      renewals.map(renewal => this.augmentRenewal(renewal));

      return renewals;
    },
    handleSearch() {
      this.resetRenewalSearch();
      this.search();
    },
    async search() {
      if (!this.isBusy) {
        this.isBusy = true;
        if (this.view === "batches") {
          this.batchViewLevel = 0;
          this.batches = await this.batchSearch();
        } else {
          this.renewals.push(...(await this.renewalSearch()));
          this.page++;
        }
        this.isBusy = false;
      }
    },
    async batchSearch() {
      const queryString = this.queryString({ batchdate: this.batchDate });
      const batches =
        (await this.$api.getRenewalBatches(queryString, {})) || [];

      batches.forEach(batch => {
        batch.batchMoment = dayjs(batch.batchDate);
      });
      return batches;
    },
    handleRenewalTableScroll(event) {
      if (
        !this.endOfRenewalsResults &&
        this.renewals.length !== 0 &&
        event.target.scrollHeight ===
          event.target.offsetHeight + event.target.scrollTop
      ) {
        this.search();
      }
    },
    resetRenewalSearch() {
      this.page = 0;
      this.endOfRenewalsResults = false;
      this.renewals = [];
    },
    clear() {
      this.renewalBeginDate = null;
      this.renewalEndDate = null;

      this.beginDate = null;
      this.endDate = null;

      this.plateNo = "";
      this.statusCodes = "3P";
    }
  },
  computed: {
    ...mapGetters({
      BDPProps: "BDPProps",
      countyConfig: "countyConfig"
    }),
    batchViewLevels() {
      const viewLevels = [
        {
          text: "Batches(" + this.batches.length + ")",
          click: () => {
            this.batchViewLevel = 0;
          }
        }
      ];
      if (this.batchViewLevel === 1) {
        viewLevels.push({
          text: "Renewals(" + this.renewals.length + ")",
          click: () => {}
        });
      }
      return viewLevels;
    },
    renewalCount() {
      return this.renewals.length;
    }
  },
  watch: {
    isBatchesView(newIsBatchesView) {
      this.view = newIsBatchesView ? "batches" : "renewals";
    },
    view(newView) {
      this.endOfRenewalsResults = false;
      this.isBatchesView = newView === "batches";
      if (newView === "batches") {
        this.renewals = this.batchRenewals;
        if (this.batchViewLevel === 0) {
          this.search();
        } else {
          this.batchViewLevel = 0;
        }
      } else {
        this.resetRenewalSearch();
        this.search();
      }
    },
    renewalBeginDate(newVal) {
      this.endOfRenewalsResults = false;
      if (newVal === null) this.beginDate = null;
      else this.beginDate = dayjs(newVal).startOf("day").format();
    },
    renewalEndDate(newVal) {
      this.endOfRenewalsResults = false;
      if (newVal === null) this.endDate = null;
      else this.endDate = dayjs(newVal).endOf("day").format();
    },
    plateNo() {
      this.endOfRenewalsResults = false;
    },
    statusCode() {
      this.endOfRenewalsResults = false;
    }
  },
  mounted() {
    this.$nextTick(() => {
      const renewalTableBody = document.querySelector(".b-table-sticky-header");

      if (renewalTableBody) {
        renewalTableBody.addEventListener(
          "scroll",
          this.handleRenewalTableScroll
        );
      }
    });
    this.hubAppIsResponsiveCheck();
  },
  activated() {
    this.resetRenewalSearch();
    this.search();
    if (this.SSE && this.SSE.readyState === 2) {
      // If SSE and readyState === closed
      this.hubAppIsResponsiveCheck();
    }
  },
  deactivated() {
    if (this.SSE !== null) this.SSE.close();
    clearTimeout(this.SSETimeout);
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/css/global-variables.scss";

.outer {
  display: flex;
  flex-direction: column;
  height: calc(100% - #{$panel-margin}* 2);
}

.renewal-grid,
.batch-grid {
  flex: 1;
  display: flex;
}
.flex-space-between {
  select {
    width: 80%;
    margin: 0 4px;
  }
}

.flex-center {
  display: flex;
  align-items: center;
}

.table-controls {
  @extend .flex-center;
  justify-content: space-between;
}

.above-grid {
  padding: 4px;
}

td {
  white-space: nowrap;
  text-overflow: ellipsis;
  word-wrap: none;
}

#status {
  width: 210px;
}

.flex-space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
::v-deep .b-table-sticky-header {
  max-height: calc(100vh - #{$nav-height} - #{$panel-margin}* 2 - 150px);
}

::v-deep td {
  height: 50px;
  vertical-align: middle !important;
}

::v-deep h2.renewal-table-nav {
  padding-left: 24px;
}

::v-deep th div {
  padding-bottom: 5px;
  padding-top: 5px;
}

::v-deep h2.table-navigation:hover {
  cursor: pointer;
}

::v-deep p {
  white-space: pre-line;
  display: inline;
}

.view-lable {
  cursor: pointer;
}

.renewal-view-control {
  margin: 6px;
}

.legend {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-bottom: 5px;
  width: 225px;
}

.legend > div {
  display: flex;
}

.color {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  margin-left: 5px;
}
</style>
