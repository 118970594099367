<template>
  <div>
    <v-dialog
      persistent
      content-class="error-dialogue"
      v-model="showBatchModal"
    >
      <div class="d-flex flex-column" style="height: 90vh">
        <div class="pb-2 pt-2 d-flex" style="height: 15vh">
          <div class="legend">
            <div v-if="!showResultsModal">
              <div class="color" style="background-color: #fff9bf"></div>
              <span> - New Metal Required</span>
            </div>
            <div v-if="!showResultsModal">
              <div class="color" style="background-color: #8ee5ee"></div>
              <span> - New Class Required</span>
            </div>
            <div v-if="showResultsModal">
              <div class="color" style="background-color: #ffd9d9"></div>
              <span> - Error</span>
            </div>
            <div v-if="showResultsModal">
              <div class="color" style="background-color: #b1dfbb"></div>
              <span> - Completed</span>
            </div>
            <div v-if="showResultsModal">
              <div class="color" style="background-color: #b8daff"></div>
              <span> - New Metal Assigned</span>
            </div>
          </div>
          <div
            class="pb-2 pt-2 d-flex justify-space-around"
            style="width: 100%"
          >
            <div v-if="showResultsModal" class="text-h3">Batch Results</div>
            <div
              v-else-if="
                !newMetalPlateClasses || newMetalPlateClasses.length === 0
              "
              class="text-h3"
            >
              No New Metal Classes
            </div>
            <div
              v-else
              v-for="(plateClass, index) of newMetalInventoryCounts"
              :key="index"
              align="center"
            >
              <p>
                New Metal Class: {{ plateClass.class }} <br />
                Plate Quantity in Range: {{ plateClass.availableMaxCount
                }}<br />
                <span v-if="plateClass.availableMaxCount > 0"
                  >First Plate in Range:
                  {{ plateClass.firstPlateInRange }}</span
                ><br />
                Plates To Be Used: {{ plateClass.inUseCount }}
              </p>
            </div>
          </div>
        </div>
        <div class="bootstrap pb-2" style="height: 65vh">
          <b-table
            v-show="showResultsModal"
            ref="batchResultsTable"
            sticky-header="65vh"
            sort-icon-left
            outlined
            :items="batchedResults"
            :fields="batchedResultFields"
            head-variant="light"
          />
          <b-table
            v-show="!showResultsModal"
            ref="batchPreviewTable"
            selectable
            sticky-header="65vh"
            sort-icon-left
            select-mode="multi"
            outlined
            :items="batchedTransactions"
            :fields="batchedRenewalFields"
            head-variant="light"
            @sort-changed="handleDeselectAll"
            @row-clicked="handleRowClick"
          />
        </div>
        <div
          align="center"
          class="d-flex justify-space-around align-center"
          style="height: 100%"
        >
          <template v-if="showResultsModal">
            <v-btn
              color="primary"
              @click="
                showResultsModal = false;
                batchedResults = [];
                $emit('cancel');
              "
              >Okay</v-btn
            >
          </template>
          <template v-else>
            <v-btn color="primary" @click="selectPreviewTableRows(10)"
              >Select 10</v-btn
            >
            <v-btn color="primary" @click="selectPreviewTableRows(50)"
              >Select 50</v-btn
            >
            <v-btn
              color="primary"
              @click="selectPreviewTableRows(batchedTransactions.length)"
              >Select All</v-btn
            >
            <v-btn color="primary" @click="handleDeselectAll"
              >Deselect All</v-btn
            >
            <v-btn
              @click="
                () => {
                  showResultsModal = true;
                  doBatch();
                }
              "
              color="primary"
              >Batch</v-btn
            >
            <v-btn @click="$emit('cancel')" color="red">Cancel</v-btn>
          </template>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "batchorgrid",
  props: {
    showBatchModal: {
      required: true,
      type: Boolean
    },
    batchedTransactions: {
      required: true,
      type: Array
    }
  },
  data: () => ({
    newMetalInventoryCounts: {},
    showResultsModal: false,
    selectedIDs: [],
    batchedResults: [],
    batchedRenewalFields: [
      {
        key: "isNewMetal",
        label: "New Metal",
        sortable: false,
        formatter(value) {
          return value ? "Yes" : "";
        }
      },
      {
        key: "plateNo",
        sortable: true
      },
      {
        key: "plateClassCode",
        label: "Class",
        sortable: true
      },
      {
        key: "issueYear"
      },
      {
        key: "currentAddress",
        sortable: true,
        formatter(value) {
          return (
            value.address1 +
            " " +
            value.address2 +
            " " +
            value.state +
            " " +
            value.postalCode
          );
        }
      },
      {
        key: "customerName",
        sortable: true
      }
    ],
    batchedResultFields: [
      {
        key: "plateno",
        sortable: true
      },
      {
        key: "class",
        label: "Class",
        sortable: true
      },
      {
        key: "issueYear"
      },
      {
        key: "address",
        sortable: true,
        formatter(value) {
          return (
            value.address1 +
            " " +
            value.address2 +
            " " +
            value.state +
            " " +
            value.postalCode
          );
        }
      },
      {
        key: "ownerName",
        sortable: true
      },
      {
        key: "err",
        label: "Error",
        formatter(err) {
          if (![null, undefined, ""].includes(err)) return err.err;
        },
        sortable: true
      }
    ]
  }),
  computed: {
    ...mapGetters({
      plateClasses: "plateClasses",
      newMetalPlateClasses: "newMetalPlateClasses",
      plateAssignConfig: "plateAssignConfig",
      countyID: "countyId",
      userObject: "userObject"
    })
  },
  watch: {
    showBatchModal(val) {
      if (val) {
        this.getInventoryCounts();
      } else {
        this.selectedIDs = [];
      }
    }
  },
  methods: {
    handleDeselectAll() {
      this.$refs.batchPreviewTable.clearSelected();
      this.selectedIDs = [];

      Object.keys(this.newMetalInventoryCounts).forEach(classCode => {
        this.newMetalInventoryCounts[classCode].inUseCount = 0;
      });
    },
    handleRowClick(item, index) {
      if (item.isNewMetal) {
        const newPlateClass =
          item.newPlate && item.newPlate.class !== item.plateClassCode
            ? item.newPlate.class
            : item.plateClassCode;
        const classObj = this.newMetalInventoryCounts[newPlateClass];
        if (this.$refs.batchPreviewTable.isRowSelected(index)) {
          classObj.inUseCount--;
          this.selectedIDs.splice(
            this.selectedIDs.indexOf(item.transactionID),
            1
          );
        } else {
          if (classObj.inUseCount < classObj.availableMaxCount) {
            classObj.inUseCount++;
            this.selectedIDs.push(item.transactionID);
          } else {
            this.$root.$emit(
              "push-alert",
              `Insufficient Class ${newPlateClass} Inventory`,
              {
                color: "warning"
              }
            );
            this.$refs.batchPreviewTable.selectRow(index);
          }
        }
      } else {
        if (this.$refs.batchPreviewTable.isRowSelected(index)) {
          this.selectedIDs.splice(
            this.selectedIDs.indexOf(item.transactionID),
            1
          );
        } else {
          this.selectedIDs.push(item.transactionID);
        }
      }
    },
    selectPreviewTableRows(selectLimit) {
      let selectCount = 0;
      const refTable = this.$refs.batchPreviewTable;
      const tableBody = refTable.$el.getElementsByTagName("tbody")[0];
      const tableRows = tableBody.getElementsByTagName("tr");
      for (
        let i = 0;
        i < this.batchedTransactions.length && selectCount < selectLimit;
        i++
      ) {
        if (!refTable.isRowSelected(i)) {
          tableRows[i].click();
          if (refTable.isRowSelected(i)) selectCount++;
        }
      }
    },
    queryString(params) {
      let queryString = "";
      let delimeter = "?"; // changes to & after first parameter
      const keys = Object.keys(params);

      keys.forEach(key => {
        if (![undefined, null, ""].includes(params[key])) {
          queryString += delimeter + key + "=" + params[key]; // add key=value to query string if it is non-empty
          if (delimeter === "?") delimeter = "&"; // change delimeter
        }
      });

      return queryString;
    },
    getInventoryCounts() {
      this.newMetalPlateClasses.forEach(async plateClass => {
        const info = {
          class: plateClass,
          availableMaxCount: 0,
          firstPlateInRange: "",
          inUseCount: 0
        };
        const plateClassInfo = this.plateClasses.find(
          plate => Number(plate.plateClassCode) === plateClass
        );
        const subrangeInfo = await this.$api.subRangeSearch(
          this.queryString({
            countyID: this.countyID,
            statusCodes: "R",
            plateClass,
            issueYear:
              plateClassInfo.plateDates[plateClassInfo.plateDates.length - 1]
                .issueYear,
            userID:
              this.plateAssignConfig === "W"
                ? this.workstationId
                : this.userObject.id
          })
        );
        if (subrangeInfo === "") {
          this.$set(this.newMetalInventoryCounts, plateClass, info);
          return;
        }
        info.firstPlateInRange = subrangeInfo[0].rangeBeginNo;
        info.availableMaxCount = subrangeInfo.reduce(
          (prev, curr) => prev + curr.remainingQuantity,
          0
        );
        this.$set(this.newMetalInventoryCounts, plateClass, info);
      });
    },

    async doBatch() {
      this.$root.$emit("setLoading", true);

      const responseJSON = await this.$api
        .doRenewalBatch({
          quantity: 0,
          includeTxIds: this.selectedIDs,
          isVerify: false
        })
        .catch(() => {
          this.$root.$emit("setLoading", false);
          this.$emit("cancel");
        });

      this.batchedResults = responseJSON.map(renewal =>
        this.$parent.augmentRenewal(renewal, true)
      );

      this.$root.$emit("setLoading", false);

      this.$parent.reprint(this.batchedResults);
    }
  }
};
</script>

<style scoped>
.legend {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-bottom: 5px;
  width: 225px;
}

.legend > div {
  display: flex;
}

.color {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  margin-left: 5px;
}

::v-deep .error-dialogue {
  background-color: white;
}
</style>
